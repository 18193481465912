'use client'

import React, { FC, ReactNode } from 'react'
import cx from 'classnames'
import { A } from 'components/basic/A'

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export interface HeadingProps {
	type: HeadingType
	title: string | ReactNode
	titleClass?: string
	headingLink?: string
}

export const Heading: FC<HeadingProps> = (props) => {
	const { title = 'heading' } = props
	return React.createElement(
		props.type,
		{ className: cx('heading', props.titleClass) },
		props.headingLink ? (
			<A
				href={props.headingLink}
				id={`${props.headingLink}-heading-link`}
			>
				{title}
			</A>
		) : (
			title
		)
	)
}
