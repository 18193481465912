import cx from 'classnames'
import { Button } from 'components/Phantom/Button'
import { Icon, IconName } from 'components/Phantom/Icon'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'
import { VideoGif } from 'components/basic/VideoGif'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useMetricRegion } from 'stores/settings'
import styles from './NewStandard.module.scss'
import { NewStandardProps } from './types'
import { getDefaultBenefits } from './utils'

export const NewStandardConnected: FC<NewStandardProps> = (props) => {
	const { ctaUrl, afterIntelligentSystem, anchorHref, className, header, subheader, benefits, theme } = props
	const metric = useMetricRegion()
	return (
		<NewStandard
			ctaUrl={ctaUrl}
			metric={metric}
			afterIntelligentSystem={afterIntelligentSystem}
			anchorHref={anchorHref}
			className={className}
			header={header}
			subheader={subheader}
			benefits={benefits}
			theme={theme}
		/>
	)
}

export const NewStandard: FC<NewStandardProps> = (props) => {
	const { benefits = getDefaultBenefits(), metric = false, header = 'The ultimate sleep solution', subheader, theme = 'white', className = '' } = props
	const [videoVisible, setVideoVisible] = useState(false)

	const videoSource = metric
		? 'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/t_video-gif/new-standard-celsius_krz5w5.mp4'
		: 'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/t_video-gif/GE-2342_20Pod_204_20Ultra_fzhj4f.mp4'

	return (
		<div
			className={cx(styles.container, className)}
			id={'new-standard'}
			data-theme={theme}
		>
			<header>
				<Type.Headline2 animateOnScroll>{header}</Type.Headline2>
				<Type.Body1 animateOnScroll>{subheader}</Type.Body1>
			</header>

			{benefits.length > 0 && (
				<ul className={styles.benefits}>
					{benefits.map((benefit, index) => (
						<Fragment key={index}>
							<Benefit
								icon={benefit.icon}
								text={benefit.text}
								index={index}
							/>
						</Fragment>
					))}
				</ul>
			)}

			<div className={styles.video_container}>
				<VideoGif src={videoSource} />

				<div className={styles.cta_container}>
					<Button.Empty
						onClick={() => {
							setVideoVisible(true)
						}}
						id={'new-standard-watch-video'}
						className={styles.video_button}
					>
						<Icon
							name={'PlayDark'}
							color={'white'}
						/>
						See how it works
					</Button.Empty>
				</div>
			</div>
			<FullScreenModal
				closeAction={() => {
					setVideoVisible(false)
				}}
				visible={videoVisible}
				aspectRatio={'16/9'}
			>
				<VideoPlayer
					metric={metric}
					forcePlaying={videoVisible}
				/>
			</FullScreenModal>
		</div>
	)
}

const Benefit: FC<{ icon: IconName; text: string; index: number }> = (props) => {
	const { icon, text, index } = props

	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref, { delay: index * 0.1 })

	return (
		<li
			key={index}
			className={styles.benefit}
			ref={ref}
		>
			<Icon
				name={icon}
				color={'black'}
			/>
			<Type.Body1>{text}</Type.Body1>
		</li>
	)
}

export const VideoPlayer: FC<{ forcePlaying: boolean; metric: boolean }> = (props) => {
	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (videoRef.current) {
			if (props.forcePlaying) {
				void videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [props.forcePlaying])

	return (
		<div className={styles.videoContainer}>
			{/* TODO: add <track> to video for captions if we have captions, for a11y purposes */}
			{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
			<video
				ref={videoRef}
				src={
					props.metric
						? 'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/pod_4_launch_metric_subtitles.mp4'
						: 'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/Pod-4-launch-with-subtitles.mp4'
				}
				preload={'metadata'}
				autoPlay={false}
				loop={false}
				controls
			/>
		</div>
	)
}
