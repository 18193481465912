/**
 * Generate ID from text
 *
 * @param text - The text to generate the ID from
 */
export const generateID = (text?: string): string => {
	if (text === undefined) {
		return ''
	}

	return text.toLowerCase().replace(' ', '-')
}
