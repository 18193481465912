import { FC } from 'react'
import { WhoWeAreProps } from './types'
import styles from './WhoWeAre.module.scss'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import { Type } from 'components/Type'

export const WhoWeAre: FC<WhoWeAreProps> = (props) => {
	return (
		<section className={styles.container}>
			<article className={styles.wrapper}>
				<Img
					sources={[
						{
							mediaQuery: '(max-width: 599px)',
							src: 'https://res.cloudinary.com/eightsleep/image/upload/who-we-are-mobile_crop_zroahg.jpg',
							dprHeight: 1600,
						},
						{
							mediaQuery: '(min-width: 600px)',
							src: 'https://res.cloudinary.com/eightsleep/image/upload/who-we-are-desktop_onmdo2.jpg',
							dprHeight: 1600,
						},
					]}
					src={'https://res.cloudinary.com/eightsleep/image/upload/who-we-are-desktop_onmdo2.jpg'}
					alt={'Woman lying in bed'}
					dprHeight={1600}
					className={styles.image}
					objectFit={'cover'}
				/>
				<Type.Eyebrow as={'h2'}>Our mission</Type.Eyebrow>
				<Type.Headline2 as={'h3'}>83 million nights of great sleep is just the start</Type.Headline2>
				<Button.Primary
					id={'personal_sleep_cta'}
					className={styles.header_cta}
					href={'/science/'}
				>
					Learn more
				</Button.Primary>
			</article>
		</section>
	)
}
